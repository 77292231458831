import request from '../utils/request';

export function sys_configList(query) {
	return request({
		url: "sys_config/list",
		method: "get",
		params: query
	});
}

export function sys_configSave(data) {
	return request({
		url: "sys_config/set",
		method: "post",
		data: data
	});
}