<template>
	<div class="conbox">
		<div class="sub_til" style="margin-top: 20px;">无线网络设置</div>
		<el-form :model="formData" :rules="formRules" ref="dataForm">
			<el-form-item label="学生账号有效期的天数" prop="effective_days">
				<el-input v-model="formData.effective_days" auto-complete="off"></el-input> 天
			</el-form-item>
			<el-form-item label="教师账号有效期的天数" prop="teacher_effective_days">
				<el-input v-model="formData.teacher_effective_days" auto-complete="off"></el-input> 天
			</el-form-item>
			<el-button type="primary" @click="formSubmit()" :loading="formLoading" size="small">保存</el-button>
		</el-form>
	</div>
</template>

<script>
	import {
		sys_configList,
		sys_configSave
	} from "../api/sys_config";
	const formJson = {};
	export default {
		data() {
			return {
				list: [],
				loading: true,
				formLoading: false,
				formData: formJson,
				deleteLoading: false,
			};
		},
		methods: {
			getList() {
				this.loading = true;
				sys_configList(this.query)
					.then((res) => {
						this.loading = false;
						console.log(res)
						this.formData.effective_days = res.data.effective_days;
						this.formData.teacher_effective_days = res.data.teacher_effective_days;
					})
					.catch(() => {
						this.loading = false;
					});
			},
			formSubmit() {
				this.$refs["dataForm"].validate((valid) => {
					if (valid) {
						this.formLoading = true;
						let data = Object.assign({}, this.formData);
						sys_configSave(data, this.formName).then((res) => {
							this.formLoading = false;
							if (res.code != 200) {
								this.$message.error(res.msg);
								return false;
							}
							this.$message.success("操作成功");
							this.formVisible = false;
							// this.$refs["dataForm"].resetFields();
							this.getList();
						});
					}
				});
			},
		},
		mounted() {},
		created() {
			this.getList();
		},
	};
</script>

<style>
	.el-input {
		width: 180px;
	}
</style>
